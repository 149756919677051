const musictrack1 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fmekaninsahibi.mp3?alt=media';
const musictrack2 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fbeatcoin.mp3?alt=media';
const musictrack3 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fnasilsinaskta.mp3?alt=media';
const musictrack4 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2F366gun.mp3?alt=media';
const musictrack5 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fbenjamins3.mp3?alt=media';
const musictrack6 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fyalan.mp3?alt=media';
const musictrack7 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fyolver.mp3?alt=media';
const musictrack8 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fciktikyineyollara.mp3?alt=media';
const musictrack9 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fnebakiyondayidayi.mp3?alt=media';
const musictrack10 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Feskimissenelere.mp3?alt=media';
const musictrack11 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fsadecedinle.mp3?alt=media';
const musictrack12 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Ftasyurek.mp3?alt=media';
const musictrack13 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fsozyaslari.mp3?alt=media';
const musictrack14 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fsiyah.mp3?alt=media';
const musictrack15 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fbendenbukadar.mp3?alt=media';
const musictrack16 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fgoal.mp3?alt=media';
const musictrack17 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Ffirtina.mp3?alt=media';
const musictrack18 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fendipten.mp3?alt=media';
const musictrack19 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fkaktus.mp3?alt=media';
const musictrack20 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Ffirstclass.mp3?alt=media';
const musictrack21 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fnormal.mp3?alt=media';
const musictrack22 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fdeli.mp3?alt=media';
const musictrack23 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fbirderdimvar.mp3?alt=media';
const musictrack24 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Ffightkulup.mp3?alt=media';
const musictrack25 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fihtiyacimyok.mp3?alt=media';
const musictrack26 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fislakkum.mp3?alt=media';
const musictrack27 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fkorsan.mp3?alt=media';
const musictrack28 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fneyimvarki.mp3?alt=media';
const musictrack29 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fneyse.mp3?alt=media';
const musictrack30 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fdipsizkuyum.mp3?alt=media';
const musictrack31 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Ftoztaneleri.mp3?alt=media';
const musictrack32 = 
    'https://firebasestorage.googleapis.com/v0/b/muziklenapp.appspot.com/o/mp3-songs%2Fbenimstiim.mp3?alt=media';
const musictrack33 = 
    'https://www.berkaymazman.com.tr/MuziklenCDN/songs/aya.mp3?alt=media';
const musictrack34 = 
    'https://www.berkaymazman.com.tr/MuziklenCDN/songs/olumeinat.mp3?alt=media';
const musictrack35 = 
    'https://www.berkaymazman.com.tr/MuziklenCDN/songs/hicsevmedimseni.mp3?alt=media';
const musictrack36 = 
    'https://www.berkaymazman.com.tr/MuziklenCDN/songs/ahzaman.mp3?alt=media';
const musictrack37 = 
    'https://www.berkaymazman.com.tr/MuziklenCDN/songs/dudu.mp3?alt=media';
const musictrack38 = 
    'https://www.berkaymazman.com.tr/MuziklenCDN/songs/ugotthat.mp3?alt=media';
const musictrack39 = 
    'https://www.berkaymazman.com.tr/MuziklenCDN/songs/smash.mp3?alt=media';
const musictrack40 = 
    'https://www.berkaymazman.com.tr/MuziklenCDN/songs/gunagarmadan.mp3?alt=media';

let tracksList = [
    {
        id: 1,
        title: 'Mekanın Sahibi - Norm Ender',
        trackURL: musictrack1,
        isFavourite: true,
    },
    {
        id: 2,
        title: 'Beatcoin - Ceza',
        trackURL: musictrack2,
        isFavourite: false,
    },
    {
        id: 3,
        title: 'Nasılsın Aşkta - Aleyna Tilki',
        trackURL: musictrack3,
        isFavourite: false,
    },
    {
        id: 4,
        title: '366. Gün - Sagopa Kajmer',
        trackURL: musictrack4,
        isFavourite: false,
    },
    {
        id: 5,
        title: 'Benjamins 3 - Rozz Kalliope & Ece Seçkin',
        trackURL: musictrack5,
        isFavourite: false,
    },
    {
        id: 6,
        title: 'Yalan - Aleyna Tilki',
        trackURL: musictrack6,
        isFavourite: false,
    },
    {
        id: 7,
        title: 'Yol Ver - Ayben',
        trackURL: musictrack7,
        isFavourite: false,
    },
    {
        id: 8,
        title: 'Çıktık Yine Yollara - Norm Ender',
        trackURL: musictrack8,
        isFavourite: false,
    },
    {
        id: 9,
        title: 'Ne Bakıyon Dayı Dayı - Keişan & Anıl Piyancı',
        trackURL: musictrack9,
        isFavourite: false,
    },
    {
        id: 10,
        title: 'Eskimiş Senelere - Aspova',
        trackURL: musictrack10,
        isFavourite: true,
    },
    {
        id: 11,
        title: 'Sadece Dinle - Norm Ender',
        trackURL: musictrack11,
        isFavourite: false,
    },
    {
        id: 12,
        title: 'Taş Yürek - Tuğba Yurt',
        trackURL: musictrack12,
        isFavourite: false,
    },
    {
        id: 13,
        title: 'Söz Yaşları - Irmak Arıcı',
        trackURL: musictrack13,
        isFavourite: false,
    },
    {
        id: 14,
        title: 'Siyah - Patron',
        trackURL: musictrack14,
        isFavourite: true,
    },
    {
        id: 15,
        title: 'Benden Bu Kadar - Patron',
        trackURL: musictrack15,
        isFavourite: false,
    },
    {
        id: 16,
        title: 'Goal - Patron',
        trackURL: musictrack16,
        isFavourite: false,
    },
    {
        id: 17,
        title: 'Fırtına - Ados',
        trackURL: musictrack17,
        isFavourite: false,
    },
    {
        id: 18,
        title: 'En Dipten - Ceg',
        trackURL: musictrack18,
        isFavourite: true,
    },
    {
        id: 19,
        title: 'Kaktüs - Norm Ender',
        trackURL: musictrack19,
        isFavourite: false,
    },
    {
        id: 20,
        title: 'First Class - Ceg',
        trackURL: musictrack20,
        isFavourite: true,
    },
    {
        id: 21,
        title: 'Normal - Ceg',
        trackURL: musictrack21,
        isFavourite: false,
    },
    {
        id: 22,
        title: 'Deli - Norm Ender',
        trackURL: musictrack22,
        isFavourite: true,
    },
    {
        id: 23,
        title: 'Bir Derdim Var - Mor ve Ötesi',
        trackURL: musictrack23,
        isFavourite: false,
    },
    {
        id: 24,
        title: 'Fight Kulüp - Killa Hakan',
        trackURL: musictrack24,
        isFavourite: true,
    },
    {
        id: 25,
        title: 'İhtiyacım Yok - Norm Ender',
        trackURL: musictrack25,
        isFavourite: false,
    },
    {
        id: 26,
        title: 'Islak Kum - Contra',
        trackURL: musictrack26,
        isFavourite: false,
    },
    {
        id: 27,
        title: 'Korsan - Sagopa Kajmer',
        trackURL: musictrack27,
        isFavourite: false,
    },
    {
        id: 28,
        title: 'Neyim Var Ki? - Ceza & Sagopa Kajmer',
        trackURL: musictrack28,
        isFavourite: true,
    },
    {
        id: 29,
        title: 'Neyse - Sagopa Kajmer',
        trackURL: musictrack29,
        isFavourite: false,
    },
    {
        id: 30,
        title: 'Dipsiz Kuyum - Aleyna Tilki',
        trackURL: musictrack30,
        isFavourite: false,
    },
    {
        id: 31,
        title: 'Toz Taneleri - Sagopa Kajmer',
        trackURL: musictrack31,
        isFavourite: true,
    },
    {
        id: 32,
        title: 'Benim Stilim - Norm Ender',
        trackURL: musictrack32,
        isFavourite: false,
    },
    {
        id: 33,
        title: 'Aya - Murda & Ezhel',
        trackURL: musictrack33,
        isFavourite: false,
    },
    {
        id: 34,
        title: 'Ölüme İnat - Hidra',
        trackURL: musictrack34,
        isFavourite: false,
    },
    {
        id: 35,
        title: 'Hiç Sevmedim Seni - Hayki',
        trackURL: musictrack35,
        isFavourite: false,
    },
    {
        id: 36,
        title: 'Ah Zaman - Derya Uluğ',
        trackURL: musictrack36,
        isFavourite: false,
    },
    {
        id: 37,
        title: 'Dudu - Tarkan',
        trackURL: musictrack37,
        isFavourite: true,
    },
    {
        id: 38,
        title: 'U Got That - Halogen',
        trackURL: musictrack38,
        isFavourite: true,
    },
    {
        id: 39,
        title: 'Smash - NebeZAO',
        trackURL: musictrack39,
        isFavourite: true,
    },
    {
        id: 40,
        title: 'Gün Ağarmadan - Mustafa Ceceli',
        trackURL: musictrack40,
        isFavourite: false,
    },
];

export default tracksList;
