import React, { Component } from 'react';

import Player from './containers/Player/Player';

class App extends Component {
	render() {
		return (
			<Player />
		);
	}
}

export default App;
